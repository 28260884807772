import './App.css';
import Camps from "./Pages/Camps";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import RecLeagues from "./Pages/RecLeagues";
import LandingPage from "./Pages/LandingPage";
import Tournaments from "./Pages/Tournaments";
import {store, persistor} from "./store";
import {Provider} from "react-redux";
import {Toaster} from 'react-hot-toast';
import {PersistGate} from "redux-persist/integration/react";
import {Route, Routes} from "react-router-dom";
import ConfirmChangePassword from "./Pages/ConfirmChangePassword";
import LeaguesSchedule from "./Pages/LeaguesSchedule";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Toaster/>
                <Routes>
                    {/* Landing Page */}
                    <Route exact path={"/"} element={<LandingPage/>}/>

                    <Route exact path={"/login/"} element={<Login/>}/>

                    <Route exact path={"/confirm-change-password/:code/"} element={<ConfirmChangePassword/>}/>

                    <Route exact path={"/register/"} element={<Register/>}/>

                    <Route exact path={"/tournaments/"} element={<Tournaments/>}/>
                    <Route exact path={"/travel-teams/"} element={<Camps/>}/>

                    <Route exact path={"/league-schedule/:id"} element={<LeaguesSchedule/>}/>
                    <Route exact path={"/rec-leagues/"} element={<RecLeagues/>}/>

                    <Route exact path={"/privacy-policy/"} element={<PrivacyPolicy/>}/>
                    <Route exact path={"/terms-and-conditions/"} element={<TermsAndConditions/>}/>

                </Routes>
            </PersistGate>
        </Provider>
    );
}

export default App;

// https://derekbedal.pythonanywhere.com
