import Layout from "../Container/Layout/Layout";
import {useNavigate} from "react-router-dom";
import React, {useRef, useState} from "react";
import {login, signup} from "../actions/actions";
import {useDispatch} from "react-redux";
import axios from "axios";
import RegisterModal from "../Container/Modal/RegisterModal";
import {Modal} from "@mui/material";
import ForgotPasswordModal from "../Container/Modal/ForgotPasswordModal";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-hot-toast";

function Login() {
    const formRef = useRef()
    const captchaRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: "",
        password: "",
    })

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const [captchaResult, setCaptchaResult] = useState(null)

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
    }

    const submit = () => {
        if (captchaResult === null) {
            toast.error("Please check the captcha before submitting !!")
        } else {
            //const token = captchaRef.current.getValue();
            dispatch(login({...data, 'captcha_value': captchaResult}, navigate))
            setCaptchaResult(null)
            captchaRef.current.reset();
       }
    }

    const [changePasswordModal, setChangePasswordModal] = useState(false)

    const handleOpenModal = () => {
        setChangePasswordModal(true)
    }
    const handleCloseModal = () => {
        setChangePasswordModal(false)
    }



    return (
        <Layout>
            <div className="my-5" style={{display: 'flex'}}>
                <div className="accounts-container">
                    <form ref={formRef} onSubmit={submit}>
                        <p className={'accounts-container-title'}>Login into <span
                            style={{color: "#EF5B2A"}}>Hometown hoops</span>
                        </p>

                        <p className={'accounts-text-input'}>Email</p>
                        <input required={true} placeholder={'Email'} onChange={onChange} name={'email'}
                               className={'form-control accounts-input'} type={'email'}/>

                        <p className={'accounts-text-input'}>Password</p>
                        <input required={true} placeholder={'Password'} onChange={onChange} name={'password'}
                               className={'form-control accounts-input'} type={'password'}/>

                        <p onClick={handleOpenModal} style={{
                            fontSize: 12,
                            textAlign: "right",
                            color: "#EF5B2A",
                            cursor: 'pointer'
                        }}>Forgot Password ?</p>

                        <div className="captcha" style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>
                            <ReCAPTCHA
                                className={'g-recaptcha'}
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                //sitekey={'6Lfhj6QlAAAAAKgTO2irKtf7Qzb-gPsqu1KHuimt'}
                                ref={captchaRef}
                                theme={'light'}
                                onChange={handleRecaptcha}
                            />
                        </div>

                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                const form = formRef.current;
                                if (!form.checkValidity()) {
                                    form.reportValidity()
                                    return
                                } else {
                                    submit()
                                }
                            }}
                            className={'form-control btn accounts-input-btn mt-4 mb-3'}
                        >Login
                        </button>
                    </form>
                    <p className={'account-meta-text'}>Don't have an account? <span onClick={() => navigate('/register/')}
                                                                                    style={{
                                                                                        color: "#EF5B2A",
                                                                                        cursor: 'pointer'
                                                                                    }}>Register now</span>
                    </p>
                </div>
            </div>

            <Modal
                open={changePasswordModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                <ForgotPasswordModal handleCloseModal={handleCloseModal}/>
            </Modal>

        </Layout>
    );
}

export default Login;