import React, {useEffect, useState} from 'react';
import Layout from "../Container/Layout/Layout";
import {BsFillCalendarFill} from "react-icons/bs";
import {MdLocationOn} from "react-icons/md";
import RegisterModal from "../Container/Modal/RegisterModal";
import {Modal} from "@mui/material";
import CampModal from "../Container/Modal/CampModal";
import Config from "../Config";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function Camps(props) {
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    const [openRegModal, setOpenRegModal] = useState(false)
    const handleOpenRegModal = (camp) => {
        setClickedCamp(camp)
        setOpenRegModal(true)
    }
    const handleCloseRegModal = () => {
        setOpenRegModal(false)
    }

    const [camps, setCamps] = useState([])
    const [clickedCamp, setClickedCamp] = useState(null)

    const get_camps = () => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/api/camps/`, config)
            .then(response => {
                console.log(response.data)
                setCamps(response.data)
            })
            .catch(error => console.log(error.message()))
    }

    useEffect(() => {
        get_camps()
    }, [])

    return (
        <Layout>
            <div className="container">
                <div className="tournaments-container">
                    {camps.length === 0 &&
                        <p className="no-tournaments-title">There are no ongoing travel teams at this
                            time, keep an eye out for emails or updates here about upcoming events. Make sure to
                            follow us on Instagram and Facebook for the most current updates!</p>
                    }
                    {camps.map((camp) => {
                        return (
                            <>
                                <p className="tournaments-title">{camp.name}</p>

                                <div style={{display: 'flex', marginBottom: 10}}>
                                    <p className="tournaments-reg-title">Registration Open !!</p>

                                    <button
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                navigate('/login/')
                                            } else {
                                                handleOpenRegModal(camp)
                                            }
                                        }}
                                        className={'btn tournaments-reg-btn'}>
                                        {(!camp.edit || !isAuthenticated) ? " Register your child here" : "Edit Registration Information"}
                                    </button>
                                </div>

                                <div className={'tournaments-reg-meta-container'}>
                                    <BsFillCalendarFill className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{camp.openDate} - {camp.closeDate}</p>
                                </div>

                                <div className={'tournaments-reg-meta-container'}>
                                    <MdLocationOn className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{camp.location}</p>
                                </div>

                                <div className="row my-3">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <p className={'tournaments-rules-title'}>Camp Information</p>
                                        {camp['rules'].map((campRule) => {
                                            return (
                                                <p className={'tournaments-rules-meta'}>
                                                    &#x2022;  {campRule.rule}
                                                </p>
                                            )
                                        })}

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        {/*<img className="tournaments-container-img" src={recLeague}/>*/}
                                        <img className="tournaments-container-img"
                                             src={camp.img}/>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {isAuthenticated &&
                <Modal
                    open={openRegModal}
                    onClose={handleCloseRegModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <RegisterModal clickedTournament={clickedCamp}
                                   type={'camps'}
                                   get_tournaments={get_camps}
                                   handleCloseRegModal={handleCloseRegModal}/>
                </Modal>
            }

        </Layout>
    );
}

export default Camps;