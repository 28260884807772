import React from 'react';
import Layout from "../Container/Layout/Layout";

function TermsAndConditions(props) {
    return (
        <Layout>
            <div className={'container my-5'}>
                <p style={{fontSize: 42, fontWeight: 600}}>Terms and Conditions</p>
                <p>
                    These terms and conditions (the "Terms and Conditions") govern the use of
                    https://hometownhoops.ca/ (the "Site"). This Site is owned and operated by
                    Derek Bedal. This Site is a news or media website.
                </p>
                <p>
                    By using this Site, you indicate that you have read and understand these Terms and
                    Conditions and agree to abide by them at all times.
                </p>

                <b>Hometown Hoops player Code of Conduct</b>

                <p>
                    1. Respect your opponents, teammates, coaches, referees, and league officials at all
                    times. Treat others the way you want to be treated.
                </p>
                <p>
                    2. Play fairly and within the rules of the game. Cheating, unsportsmanlike conduct,
                    and intentionally injuring other players will not be tolerated.
                </p>
                <p>
                    3. Maintain a positive attitude and demeanor, both on and off the court. Remember
                    that this is a recreational league and the main objective is to have fun.
                </p>
                <p>
                    4. Avoid excessively aggressive or overly competitive behavior. While we encourage
                    healthy competition, please keep in mind that this is not a professional league
                    and winning is not the only goal. We are aware some events encourage more
                    competition than others, however this does not mean we no longer expect
                    participants to follow the rules.
                </p>
                <p>
                    5. Refrain from using profanity and making offensive gestures or comments in a
                    derogatory manner, or engaging in any form of discrimination. Our league
                    welcomes all players, regardless of race, gender, religion, or sexual orientation.
                </p>
                <p>
                    6. Show up on time and be ready to play. If you cannot make a game or will be late,
                    please notify your team captain as soon as possible, they will be responsible for
                    passing along the information if it will be affecting others.
                </p>
                <p>
                    7. Be responsible for your own equipment and personal belongings. Hometown
                    Hoops is not responsible for lost or stolen items
                </p>
                <p>
                    8. Be respectful of the facilities we use for our games. Do not litter, damage, or
                    deface any property.</p>
                <p>
                    9. Any disputes or concerns should be addressed with the league officials in a
                    professional and respectful manner.
                </p>
                <p>
                    10. Failure to abide by these rules may result in disciplinary action, including
                    suspension or expulsion from the league without a refund. Hometown Hoops has
                    the right to refuse anyone.
                </p>
                <p>
                    11. Remember, the most important thing is to have fun and enjoy playing basketball
                    with your teammates and opponents. Let's work together to create a positive and
                    enjoyable experience for everyone involved.
                </p>

                <b>Intellectual Property</b>
                <p>
                    All content published and made available on our Site is the property of Derek Bedal and
                    the Site's creators. This includes, but is not limited to images, text, logos, documents,
                    downloadable files and anything that contributes to the composition of our Site.
                    Acceptable Use
                </p>
                <p>
                    As a user of our Site, you agree to use our Site legally, not to use our Site for illegal
                    purposes, and not to:
                </p>
                <p>
                    ● Harass or mistreat other users of our Site;
                </p>
                <p>
                    ● Violate the rights of other users of our Site;
                </p>
                <p>
                    ● Violate the intellectual property rights of the Site owners or any third party to the
                    Site
                </p>
                <p>
                    ● Hack into the account of another user of the Site
                </p>
                <p>
                    ● Act in any way that could be considered fraudulent; or
                </p>
                <p>
                    ● Post any material that may be deemed inappropriate or offensive.
                </p>
                <p>
                    If we believe you are using our Site illegally or in a manner that violates these Terms
                    and Conditions, we reserve the right to limit, suspend or terminate your access to our
                    Site. We also reserve the right to take any legal steps necessary to prevent you from
                    accessing our Site.
                </p>
                <b>Accounts</b>
                <p>
                    When you create an account on our Site, you agree to the following:
                </p>
                <p>
                    1. You are solely responsible for your account and the security and privacy of your
                    account, including passwords or sensitive information attached to that account;
                    and
                </p>
                <p>
                    2. All personal information you provide to us through your account is up to date,
                    accurate, and truthful and that you will update your personal information if it
                    changes.
                </p>
                <p>
                    We reserve the right to suspend or terminate your account if you are using our Site
                    illegally or if you violate these Terms and Conditions.
                </p>
                <b>Links to Other Websites</b>
                <p>
                    Our Site contains links to third party websites or services that we do not own or control.
                    We are not responsible for the content, policies, or practices of any third party website
                    or service linked to on our Site. It is your responsibility to read the terms and conditions
                    and privacy policies of these third party websites before using these sites.
                </p>
                <b>Limitation of Liability</b>
                <p>Derek Bedal and our directors, officers, agents, employees, subsidiaries, and affiliates
                    will not be liable for any actions, claims, losses, damages, liabilities and expenses
                    including legal fees from your use of the Site.
                </p>
                <b>Indemnity</b>
                <p>Except where prohibited by law, by using this Site you indemnify and hold harmless
                    Derek Bedal and our directors, officers, agents, employees, subsidiaries, and affiliates
                    from any actions, claims, losses, damages, liabilities and expenses including legal fees
                    arising out of your use of our Site or your violation of these Terms and Conditions.
                </p>
                <b>Applicable Law</b>
                <p>
                    These Terms and Conditions are governed by the laws of the Province of Ontario.
                </p>
                <b>
                    Photo Release
                </b>
                <p>
                    By signing up and participating in the events, I hereby grant HOMETOWN HOOPS, on
                    behalf of myself, and on behalf of my child(ren)/ward(s), the irrevocable right and
                    permission to photograph and/or record me or my child(ren)/ward(s) in connection with
                    HOMETOWN HOOPS and to use the photograph and/or recording for promotional
                    purposes. I waive any right to inspect or approve the use of the photograph and/or
                    recording, and acknowledge and agree that the rights granted to this release are without
                    compensation of any kind.
                </p>

                <b>Severability</b>
                <p>
                    If at any time any of the provisions set forth in these Terms and Conditions are found to
                    be inconsistent or invalid under applicable laws, those provisions will be deemed void
                    and will be removed from these Terms and Conditions. All other provisions will not be
                    affected by the removal and the rest of these Terms and Conditions will still be
                    considered valid.
                </p>

                <b>Changes</b>
                <p>
                    These Terms and Conditions may be amended from time to time in order to maintain
                    compliance with the law and to reflect any changes to the way we operate our Site and
                    the way we expect users to behave on our Site. We will notify users by email of
                    changes to these Terms and Conditions or post a notice on our Site.
                </p>
                <b>Contact Details</b>
                <p>Please contact us if you have any questions or concerns. Our contact details are as
                    follows:</p>
                <p>
                    (226) 757-0611
                </p>
                <p>
                    derek.bedal@icloud.com
                </p>
            </div>
        </Layout>
    );
}

export default TermsAndConditions;