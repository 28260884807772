import React from 'react';
import LandingPageTab from "./LandingPageTab";
import campImg from '../../assets/images/camps.jpg';
import recLeague from '../../assets/images/rec-league.jpg';
import tournaments from '../../assets/images/tournaments.jpg';

function LandingPageTabs({}) {
    return (
        <div className={'landing-page-tabs'}>
            <p className={'landing-page-tabs-title'}>Discover your next basketball adventure with
                Hometown Hoops</p>
            <div className="row">
                <LandingPageTab img={tournaments}
                                link={'/tournaments/'} title={'Tournaments'}
                                para={"Discover all the details about our upcoming basketball tournaments, click here!"}/>
                <LandingPageTab img={campImg}
                                link={'/travel-teams/'} title={'Travel Teams'}
                                para={"Discover all the details about our upcoming travel teams, click here!"}/>
                <LandingPageTab img={recLeague}
                                link={'/rec-leagues/'} title={'Rec Leagues'}
                                para={"Discover all the details about our upcoming adult rec leagues, click here!"}/>
            </div>
        </div>
    );
}

export default LandingPageTabs;