import Navbar from "./Navbar";
import HeroMidSection from "./HeroMidSection";
import clsx from "clsx";

function Hero({setShowSidebar, title, meta, metaSmall, isAuthenticated}) {
    return (
        <div className={clsx('hero-landing', metaSmall && "hero-landing-background")}>
            {!metaSmall &&
                <>
                    <div className={'hero-cover'}></div>
                    <div className={'dark-overlay'}></div>
                </>
            }
            <div className="hero-body">
                <Navbar setShowSidebar={setShowSidebar} isAuthenticated={isAuthenticated}/>
                <HeroMidSection title={title} meta={meta} metaSmall={metaSmall}/>
            </div>
        </div>
    );
}

export default Hero;