import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {toast} from "react-hot-toast";
import {raise_error} from "../actions/actions";
import Config from "../Config";
import axios from "axios";
import {Modal} from "@mui/material";
import ForgotPasswordModal from "../Container/Modal/ForgotPasswordModal";
import Layout from "../Container/Layout/Layout";

function ConfirmChangePassword(props) {
    const formRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: "",
        password1: "",
        password2: ""
    })

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        const code = location.pathname.split('/')[2]
        setData({...data, email: atob(code)})
    }, [])

    const changePassword = () => {
        const config = Config()
        if (data.password1 !== data.password2) {
            toast.error("Passwords must match", {duration: 5000})
        } else {
            toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/accounts/confirm-change-password/`, data, config), {
                loading: 'Changing Password...',
                success: (res) => {
                    navigate('/login')
                    return res.data.message
                },
                error: (err) => {
                    return raise_error(err)
                }
            })
        }
    }

    return (
        <Layout>
            <div className="my-5" style={{display: 'flex'}}>
                <div className="accounts-container">
                    <form ref={formRef}>
                        <p className={'accounts-container-title'}>Change Password</p>

                        <p className={'accounts-text-input'}>Email</p>
                        <input required={true} placeholder={'Email'} onChange={onChange} name={'email'}
                               value={data.email}
                               disabled={true}
                               className={'form-control accounts-input'} type={'email'}/>

                        <p className={'accounts-text-input'}>Enter New Password</p>
                        <input required={true} placeholder={'Password'} onChange={onChange} name={'password1'}
                               pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                               value={data.password1}
                               className={'form-control accounts-input'} type={'password'}/>

                        <p className={'accounts-text-input'}>Confirm New Password</p>
                        <input required={true} placeholder={'Password'} onChange={onChange} name={'password2'}
                               value={data.password2}
                               className={'form-control accounts-input'} type={'password'}/>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                const form = formRef.current;
                                if (!form.checkValidity()) {
                                    form.reportValidity()
                                    return
                                } else {
                                    changePassword()
                                }
                            }}
                            className={'form-control btn accounts-input-btn mt-4 mb-3'}
                        >Submit
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default ConfirmChangePassword;