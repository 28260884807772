import React from 'react';
import derek from "../../assets/images/derek.jpg";

function AboutUs({}) {
    return (
        <div id="about-us" className={'about-us-section my-5'}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <p className={'about-us-title'}>About Us</p>
                    <p className={'about-us-meta'}>Hometown Hoops was founded by Derek Bedal who saw a need for more opportunities
                        to play basketball in the Leamington and surrounding areas. His idea started with a tournament in 2021.
                        This idea then grew to not only host tournaments but to also include a recreational summer league for
                        young adults the next summer.
                        Derek plans to grow his business to include skill development camps for elementary and high school age
                        players.
                        Derek’s love of basketball began as a young boy and grew as he competed on multiple levels in high school.
                        During this time he spent many summers attending various basketball camps such as PGC, Breakthrough
                        Basketball, and others. Derek attended these camps to constantly grow his basketball IQ and his basketball
                        skills. He hopes his love of the game will spread throughout the local communities as he provides multiple
                        opportunities.</p>
                    <p className={'about-us-meta'}>Derek’s Basketball Portfolio:</p>
                    <p className={'about-us-meta'}>&#x2022; Played competitive basketball from grades 6-12</p>
                    <p className={'about-us-meta'}>&#x2022; Played for Leamington Cougars</p>
                    <p className={'about-us-meta'}>&#x2022; Played for Kingsville Class</p>
                    <p className={'about-us-meta'}>&#x2022; Played AAU Basketball in Detroit, Michigan for MWA Elite</p>
                    <p className={'about-us-meta'}>&#x2022; Played WECSSAA high school basketball for UMEI</p>
                    <p className={'about-us-meta'}>&#x2022; Team manager for University of Waterloo’s men’s basketball team for
                        two seasons (2021-2023)</p>
                    <p className={'about-us-meta'}>&#x2022; Co-Coached UMEI’s boy’s basketball team (2023)</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img src={derek} className={'about-us-img'}/>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;