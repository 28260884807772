import React from 'react';
import Layout from "../Container/Layout/Layout";

function PrivacyPolicy(props) {
    return (
        <Layout>
            <div className={'container my-5'}>
                <p style={{fontSize: 42, fontWeight: 600}}>Privacy Policy</p>
                <p>In brief: We are Hometown Hoops (“we” or “us”). We respect your privacy and it’s our
                    commitment to preserving your trust and confidence. Because your privacy is important,
                    we have created this privacy policy in order to demonstrate this commitment. The
                    following discloses our information gathering and dissemination practices for this
                    website.
                </p>
                <b>
                    USE OF YOUR IP ADDRESS
                </b>
                <p>
                    An IP (Internet Protocol) address is a 32-bit numeric address written as four numbers
                    separated by periods. It is a unique identifier for a computer or device on a network
                    such as the internet. We use your IP address to help diagnose problems with our server
                    and to administer our website. Your IP address is being used to identify you and to
                    collect broad information to provide you the best experience.
                </p>
                <b>COOKIES</b>
                <p>
                    A “cookie” is a small piece of information that is stored by your internet browser
                    software on your computer’s hard drive. Cookies are commonly used to facilitate secure
                    website connections via a unique username and password so that you can buy things in
                    an online store and access services that are in password-protected areas of a site. They
                    also may be used to facilitate automatic log-in (you can save your own username and
                    password so you don’t have to re-enter it each time you visit). Cookies are used to
                    authenticate website users and keep track of the user session. They allow us to better
                    analyze our site’s traffic patterns and to provide improved content, throughput,
                    advertisements, products and services. They help us to refine and enhance the visitor
                    registration process and add personalization features to customize the site to your
                    needs. Cookies also help identify information that we may need to better serve you
                    should there be any problems with your use of this site. This kind of information may
                    include the web browser you use, the type of computer, the operating system, the
                    internet service providers, and other similar information. This information is used for
                    various personalization features. We may share this information with our partners,
                    suppliers, and other third parties so that they can better serve you too. We may
                    sometimes display advertisements on this site from companies outside of our own.
                    These ads may contain their own cookies. While we use cookies in other parts of our
                    website, cookies received with electronic ads are collected by these other companies,
                    and we do not have access to this information. Select customer data may be shared
                    with the advertising companies.
                </p>

                <b>WE ́RE RUNNING GOOGLE FONTS</b>
                <p>
                    Due to the law of the German Government and the European Union, we want to make
                    you aware that we use Google Fonts. If you are a visitor of our website, you confirm that
                    a connection is made to Google servers with every web visit and the IP address is
                    shared with Google. If you don’t agree with this procedure, please leave this website.
                    COLLECTION AND USE OF DATA
                </p>
                <p>
                    Our site’s registration form requires users to give us contact information (such as name
                    and/or e-mail address, private address), unique identifiers, and demographic
                    information (such as sex or birthdate). We use this information to enhance the site to
                    offer you a more personalized experience. We also use the information to communicate
                    information about our company, promotional material such as contests, questionnaires
                    and surveys, and promotional material from some of our partners. Users may opt-out of
                    receiving future mailings by using the tool explained in the “Updating and Removal of
                    Data” section below. Unique identifiers are collected to verify the user’s identity, for use
                    as account numbers in our record system, and for other purposes. Demographic profile
                    data is also collected at our site. We use this data to tailor the user experience, showing
                    you content that is most likely to be of interest to you, and displaying the content
                    according to your preferences.
                </p>
                <b>THIRD-PARTY ADVERTISING</b>
                <p>
                    As an active subscriber to Hometown Hoops you will not receive any third-party
                    advertisements. If you wish to opt-out of our advertising policy, please contact us by
                    clicking here.
                </p>
                <b>LINKS TO WEBSITES OUTSIDE OF THIS ONE</b>
                <p>
                    This site contains at the moment no links to other sites outside of our own network. In
                    case we provide links to other sites outside of our own network, we are not responsible
                    for the privacy practices or the content of such websites.
                </p>
                <b>SECURITY</b>
                <p>
                    The security of your information is of concern to us. When you place orders or subscribe
                    to this site, your information is transmitted using Secure Sockets Layer (SSL)
                    technology. Furthermore, we have implemented industry-standard security mechanisms
                    and procedures to protect data from loss, misuse, and unauthorized access, disclosure,
                    alteration, and destruction. While we are focused on the security of your information, no
                    security is 100% effective and we cannot promise (and you should not expect), that your
                    information will remain secure in all circumstances.
                </p>
                <b>
                    SHARING YOUR INFORMATION
                </b>
                <p>
                    We don’t share or sell the information that you provide to us.
                </p>
                <b>
                    NOTICE
                </b>
                <p>
                    If we are legally required to notify you about a security incident we will notify you by
                    e-mail. Therefore it’s important that you provide us with your correct e-mail or update
                    your e-mail if needed.
                </p>
                <b>
                    UPDATING AND REMOVAL OF DATA
                </b>
                <p>
                    You may have your name removed or updated from our e-mail waitlist at any time, by
                    contacting us. Please read the information in this area very carefully so that you do not
                    inadvertently discontinue the receipt of materials that you actually do wish to receive.
                    CONTACTING THE WEBSITE
                </p>
                <p>
                    If you have any questions about this statement, the practices of this website or any
                    other questions, you can contact us via e-mail at: derek.bedal@icloud.com
                    CHANGES AND CLARIFICATIONS
                </p>
                <p>
                    We reserve the right to modify, amend, or clarify this policy at any time by posting a
                    revised privacy policy on our website.
                </p>
            </div>
        </Layout>
    );
}

export default PrivacyPolicy;