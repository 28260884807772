import React from 'react';
import {AiFillFacebook, AiFillInstagram, AiFillTwitterCircle} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate()
    return (
        <div className={'footer'}>
            <div className="container">
                <div className={'footer-container row'}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <p className={'contact-us-title'}>Contact Us</p>
                        <p className={'contact-us-meta'}>Leamington, Ontario</p>

                        <p className={'contact-us-meta'}>226-757-0611</p>
                        <p className={'contact-us-meta'}>hometownhoopsmail@gmail.com</p>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <p className={'contact-us-title'}>Get Started</p>
                        <p className={'footer-link'} onClick={() => {
                            navigate('/tournaments/')
                        }}>Tournaments</p>
                        <p className={'footer-link'} onClick={() => {
                            navigate('/camps/')
                        }}>Camps</p>
                        <p className={'footer-link'} onClick={() => {
                            navigate('/rec-leagues/')
                        }}>Leagues</p>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <p className={'contact-us-title'}>Company</p>
                        <p>
                            <a className={'footer-link'}
                               onClick={() => {
                                   navigate('/')
                               }}
                               href="#about-us"
                            >About Us</a>
                        </p>
                        <p className={'footer-link'} onClick={() => {
                            navigate('/privacy-policy/')
                        }}>Privacy Policy</p>
                        <p className={'footer-link'} onClick={() => {
                            navigate('/terms-and-conditions/')
                        }}>Terms & Conditions</p>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div style={{display: "flex"}}>
                            <p className={'contact-us-title'}>Connect with us: </p>
                            <a target={'_blank'} href={'https://www.facebook.com/HometownHoopsBasketball'}><AiFillFacebook
                                className={'footer-logo'}/></a>
                            <a target={'_blank'}
                               href={'https://instagram.com/hometownhoops_?igshid=YmMyMTA2M2Y='}><AiFillInstagram
                                className={'footer-logo'}/></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-3">
                        <p className={'footer-copyright'}>
                            © Copyright 2023 Hometown hoops.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;