import Config from "../Config";
import axios from "axios";
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SIGNUP_SUCCESS,
    SIGNUP_FAILED,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAILED
} from './types';
import {toast} from "react-hot-toast";

export const load_user = () => async dispatch => {
    const config = Config()
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/load-user/`, config)
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: LOAD_USER_FAILED
        })
    }
}

export const signup = (data, navigate) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    await toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/accounts/register/`, data, config), {
        loading: 'Signing up...',
        success: (res) => {
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: res.data
            });
            navigate('/')
            return 'Account created'
        },
        error: (err) => {
            dispatch({
                type: SIGNUP_FAILED
            })
            return raise_error(err)
        }
    })
}

export const login = (data, navigate) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    await toast.promise(axios.post(`${process.env.REACT_APP_API_URL}/accounts/login/`, data, config), {
        loading: 'Logging in...',
        success: (res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })
            navigate('/')
            return "Logged In, Welcome"
        },
        error: (err) => {
            dispatch({
                type: LOGIN_FAILED
            })
            return raise_error(err)
        }
    })
}

export const logout = () => async dispatch => {
    try {
        dispatch({
            type: LOGOUT_SUCCESS
        })
    } catch (e) {
        dispatch({
            type: LOGOUT_FAILED
        })
    }
}

export const raise_error = (err) => {
    let error = ''
    console.log(err.response.data)
    Object.keys(err.response.data).forEach((key, index) => {
        if (typeof err.response.data === 'object')
            error += err.response.data[key]
        else
            err.response.data[key].map(item => error += item)
    })

    return error
}
