import Layout from "../Container/Layout/Layout";
import Sponsors from "../Container/LandingPage/Sponsors";
import AboutUs from "../Container/LandingPage/AboutUs";
import LandingPageTabs from "../Container/LandingPage/LandingPageTabs";

function LandingPage({}) {
    return (
        <Layout>
            <div className="container">
                <div className={'landing-page'}>
                    <LandingPageTabs/>
                    <AboutUs/>
                    <Sponsors/>
                </div>
            </div>
        </Layout>
    );
}

export default LandingPage;