import Layout from "../Container/Layout/Layout";
import {useNavigate} from "react-router-dom";
import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {login, signup} from "../actions/actions";
import {toast} from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

function Register() {
    const formRef = useRef()
    const captchaRef = useRef(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState({
        full_name: "",
        email: "",
        password1: "",
        password2: "",
        phone_number: ""
    })

    const onChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const [captchaResult, setCaptchaResult] = useState(null)

    const handleRecaptcha = (value) => {
        setCaptchaResult(value)
    }

    const submit = () => {
        if (captchaResult === null) {
            toast.error("Please check the captcha before submitting !!")
        } else {
            dispatch(signup({...data, 'captcha_value': captchaResult}, navigate))
            setCaptchaResult(null)
            captchaRef.current.reset();
        }
    }

    return (
        <Layout>
            <div className="my-5" style={{display: 'flex'}}>
                <div className="accounts-container">
                    <form ref={formRef}>
                        <p className={'accounts-container-title'}>Create your <span style={{color: "#EF5B2A"}}>account</span></p>

                        <p className={'accounts-text-input'}>Name</p>
                        <input required={true} placeholder={'Full Name'} name={'full_name'} onChange={onChange}
                               className={'form-control accounts-input'} type={'text'}/>

                        <p className={'accounts-text-input'}>Phone Number</p>
                        <input required={true} placeholder={'Phone Number'} name={'phone_number'} onChange={onChange}
                               className={'form-control accounts-input'} type={'number'}/>

                        <p className={'accounts-text-input'}>Email</p>
                        <input required={true} placeholder={'Email'} name={'email'} onChange={onChange}
                               className={'form-control accounts-input'} type={'email'}/>

                        <p className={'accounts-text-input'}>Password</p>
                        <input required={true} placeholder={'Password'} name={'password1'} onChange={onChange}
                               pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                               className={'form-control accounts-input'} type={'password'}/>

                        <p className={'accounts-text-input'}>Confirm Password</p>
                        <input required={true} placeholder={'Confirm Password'} name={'password2'} onChange={onChange}
                               className={'form-control accounts-input'} type={'password'}/>

                        <div className="captcha" style={{transform: "scale(0.85)", transformOrigin: "0 0"}}>
                            <ReCAPTCHA
                                className={'g-recaptcha'}
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                // sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                ref={captchaRef}
                                theme={'light'}
                                onChange={handleRecaptcha}
                            />
                        </div>

                        <p className={'account-meta-text'}>
                            <input required={true} className="form-check-input" type="checkbox" value=""
                                   id="flexCheckChecked"/> I have read and agree to Terms and
                            Conditions and Privacy Policy.</p>

                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                const form = formRef.current;
                                if (!form.checkValidity()) {
                                    form.reportValidity()
                                    return
                                } else {
                                    submit()
                                }
                            }}
                            className={'form-control btn accounts-input-btn mb-3'}
                        >Register
                        </button>

                        <p className={'account-meta-text'}>Don't have an account?
                            <span onClick={() => navigate('/login/')}
                                  style={{color: "#EF5B2A", cursor: 'pointer'}}>Sign in now</span>
                        </p>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Register;