import React, {useEffect} from 'react';
import clsx from "clsx";
import {IoIosCloseCircle} from "react-icons/io";
import {useLocation, useNavigate} from "react-router-dom";

function Sidebar({showSidebar, setShowSidebar}) {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setShowSidebar(false)
    }, [location.pathname])
    return (
        <div className={clsx('sidebar', showSidebar ? 'sidebar-active' : 'sidebar-inactive')}>
            <IoIosCloseCircle
                className={showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item'}
                onClick={() => {
                    setShowSidebar(false)
                }}/>
            <div className="sidebar-item-list">
                <div
                    onClick={() => {
                        navigate('/')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <p>Home</p>
                </div>
                <div
                    onClick={() => {
                        navigate('/tournaments/')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <p>Tournaments</p>
                </div>
                <div
                    onClick={() => {
                        navigate('/camps/')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <p>Camps</p>
                </div>
                <div
                    onClick={() => {
                        navigate('/rec-leagues/')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <p>Rec Leagues</p>
                </div>
                <div className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <button className={'btn nav-btn login-btn'} onClick={() => {
                        navigate('/login/')
                    }}>Login
                    </button>
                </div>
                <div className={clsx('sidebar-item', showSidebar ? 'display-sidebar-item' : 'hide-sidebar-item')}>
                    <button className={'btn nav-btn register-btn'} onClick={() => {
                        navigate('/register/')
                    }}>Register
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;