import {useNavigate} from "react-router-dom";

function LandingPageTab({img, link, title, para}) {
    const navigate = useNavigate()
    return (
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3"
             onClick={() => navigate(link)}
        >
            <div className={'landing-page-tab shadow'}>
                <div className="landing-page-tab-img-container">
                    <img className={'landing-page-tab-img'} style={{objectFit: "cover"}}
                         src={img}/>
                </div>
                <div className={'landing-page-tab-text-container'}>
                    <p className={'landing-page-tab-text-title'}>{title}</p>
                    <p className={'landing-page-tab-text-meta'}>{para}</p>
                </div>
            </div>
        </div>
    );
}

export default LandingPageTab;