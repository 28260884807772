import React from 'react';

function HeroMidSection({title, meta, metaSmall}) {
    return (
        <div className={'hero-mid-section'}>
            <p className={'hero-mid-section-title'}>{title}</p>
            <p className={!metaSmall ? 'hero-mid-section-meta' : 'hero-mid-section-meta-sm'}>{meta}</p>
        </div>
    );
}

export default HeroMidSection;