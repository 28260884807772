import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/landing-page.css'
import './css/sidebar.css';
import './css/layout.css';
import './css/accounts.css';
import './css/tournaments.css';
import './css/modal.css';
import './css/league-schedule.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);

reportWebVitals();
