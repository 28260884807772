import React, {useEffect, useState} from 'react';
import Layout from "../Container/Layout/Layout";
import {BsFillCalendarFill} from "react-icons/bs";
import {MdLocationOn} from "react-icons/md";
import RegisterModal from "../Container/Modal/RegisterModal";
import {Modal} from "@mui/material";
import Config from "../Config";
import axios from "axios";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

function RecLeagues(props) {
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const [openRegModal, setOpenRegModal] = useState(false)
    const handleOpenRegModal = (league) => {
        setClickedLeague(league)
        setOpenRegModal(true)
    }
    const handleCloseRegModal = () => {
        setOpenRegModal(false)
    }

    const [leagues, setLeagues] = useState([])
    const [clickedLeague, setClickedLeague] = useState(null)

    const get_leagues = () => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/api/leagues/`, config)
            .then(response => {
                console.log(response.data)
                setLeagues(response.data)
            })
            .catch(error => console.log(error.message()))
    }

    useEffect(() => {
        get_leagues()
    }, [])

    return (
        <Layout>
            <div className="container">
                <div className="tournaments-container">
                    {leagues.length === 0 &&
                        <p className="no-tournaments-title">There are no ongoing leagues at this
                            time, keep an eye out for emails or updates here about upcoming events. Make sure to
                            follow us on Instagram and Facebook for the most current updates!</p>
                    }
                    {leagues.map((league) => {
                        return (
                            <>
                                <p className="tournaments-title">{league.name}</p>

                                <div style={{display: 'flex', marginBottom: 10}}>
                                    {!league.closed ?
                                        <p className="tournaments-reg-title">Registration Open !!</p>
                                        :
                                        <p className="tournaments-reg-title">Registration Closed !!</p>
                                    }

                                    <button
                                        onClick={() => {
                                            if (league.closed) {
                                                navigate(`/league-schedule/${league.id}/`)
                                            } else {
                                                if (!isAuthenticated) {
                                                    navigate('/login/')
                                                } else {
                                                    handleOpenRegModal(league)
                                                }
                                            }
                                        }}
                                        className={'btn tournaments-reg-btn'}>
                                        {!league.closed ?
                                            (!league.edit || !isAuthenticated) ? "Register your team here" : "Edit Registration Information"
                                            :
                                            "Click here to see schedule"
                                        }
                                    </button>
                                </div>

                                <div className={'tournaments-reg-meta-container'}>
                                    <BsFillCalendarFill className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{league.openDate} - {league.closeDate}</p>
                                </div>

                                <div className={'tournaments-reg-meta-container'}>
                                    <MdLocationOn className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{league.location}</p>
                                </div>

                                <div className="row my-3">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <p className={'tournaments-rules-title'}>League information</p>

                                        {league['rules'].map((leagueRule) => {
                                            return (
                                                <p className={'tournaments-rules-meta'}>
                                                    &#x2022;  {leagueRule.rule}
                                                </p>
                                            )
                                        })}

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        {/*<img className="tournaments-container-img" src={recLeague}/>*/}
                                        <img className="tournaments-container-img"
                                             src={league.img}/>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {isAuthenticated &&
                <Modal
                    open={openRegModal}
                    onClose={handleCloseRegModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <RegisterModal clickedTournament={clickedLeague}
                                   get_tournaments={get_leagues} type={'leagues'}
                                   handleCloseRegModal={handleCloseRegModal}/>
                </Modal>
            }
        </Layout>
    );
}

export default RecLeagues;