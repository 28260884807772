import logo from '../../assets/images/logo 2.png'
import {GiHamburgerMenu} from "react-icons/gi";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../../actions/actions";

function Navbar({setShowSidebar, isAuthenticated}) {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [backgroundColor, setBackgroundColor] = useState("#EF5B2A")

    useEffect(() => {
        if (location.pathname === '/rec-leagues/') {
            setBackgroundColor("black")
        } else if (location.pathname === '/tournaments/') {
            setBackgroundColor("black")
        } else if (location.pathname === '/travel-teams/') {
            setBackgroundColor("black")
        } else {
            setBackgroundColor("#EF5B2A")
        }
    }, [location.pathname])

    return (
        <div className={'navbar'}>
            <div className="container">
                <img src={logo} className={'navbar-logo'}
                     onClick={() => {
                         navigate('/')
                     }}
                />
                <p className={'navbar-link navbar-link-lg'}
                   onClick={() => navigate('/tournaments/')}
                >Tournaments</p>
                <p className={'navbar-link navbar-link-lg'}
                   onClick={() => navigate('/travel-teams/')}
                >Travel Teams</p>
                <p className={'navbar-link navbar-link-lg'}
                   onClick={() => navigate('/rec-leagues/')}
                >Rec Leagues</p>
                <a className={'navbar-link'}
                   onClick={() => {
                       navigate('/')
                   }}
                   href="#about-us"
                >About Us</a>
                <GiHamburgerMenu style={{fontSize: 18}}
                                 onClick={() => setShowSidebar(prev => !prev)}
                                 className={'navbar-link navbar-link-sm'}/>
                {!isAuthenticated ?
                    <button style={{backgroundColor: backgroundColor}}
                            onClick={() => {
                                navigate('/login/')
                            }}
                            className={'btn navbar-link login-btn navbar-link-lg'}>Login
                    </button>
                    :
                    <button style={{backgroundColor: backgroundColor}}
                            onClick={() => {
                                dispatch(logout())
                            }}
                            className={'btn navbar-link login-btn navbar-link-lg'}>Logout
                    </button>
                }
            </div>
        </div>
    );
}

export default Navbar;