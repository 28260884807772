import sponsor1 from '../../assets/images/sponsor1.png'
import sponsor2 from '../../assets/images/sponsor2.png'
import sponsor3 from '../../assets/images/sponsor3.png'
import sponsor4 from '../../assets/images/sponsor4.png'
import sponsor5 from '../../assets/images/sponsor5.png'

function Sponsors() {
    return (
        <div className={'sponsors-section my-5'}>
            <p className={'sponsors-section-title'}>Our Sponsor's</p>

            <p className={'sponsors-section-meta'}>We are incredibly grateful for the support of our sponsor's that help
                make Hometown Hoops a success.</p>

            <img src={sponsor1} className={'our-sponsors-logo'}/>
            <img src={sponsor2} className={'our-sponsors-logo'}/>
            <img src={sponsor3} className={'our-sponsors-logo'}/>
            <img src={sponsor4} className={'our-sponsors-logo'}/>
            <img src={sponsor5} className={'our-sponsors-logo'}/>
            

        </div>
    );
}

export default Sponsors;