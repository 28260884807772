import React, {useRef, useState} from 'react';
import axios from "axios";
import {toast} from "react-hot-toast";
import {AiOutlineClose} from "react-icons/ai";

export function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

function ForgotPasswordModal({handleCloseModal}) {
    const formRef = useRef()
    const [modalStyle] = useState(getModalStyle);
    const [email, setEmail] = useState("")

    const submit = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        axios.post(`${process.env.REACT_APP_API_URL}/accounts/change-password/`, {email: email}, config)
            .then(() => {
                toast.success("A password change link has been sent to the email you entered")
            })
            .catch(err => {
                toast.error("Could not send password change link to the email you entered, please try again later")
            })
        handleCloseModal()
    }

    return (
        <div style={modalStyle} className={'forgot-password-modal'}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <p className={'modal-body-text'}>Enter Email</p>
                <AiOutlineClose onClick={handleCloseModal} className={'modal-body-close'}/>
            </div>
            <form ref={formRef}>

                <input required={true} placeholder={'Enter Email'} onChange={(e) => {
                    setEmail(e.target.value)
                }} name={'email'}
                       className={'form-control accounts-input'} type={'email'}/>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        const form = formRef.current;
                        if (!form.checkValidity()) {
                            form.reportValidity()
                            return
                        } else {
                            submit()
                        }
                    }}
                    className={'form-control btn accounts-input-btn mt-2'}
                >Submit
                </button>
            </form>
        </div>
    );
}

export default ForgotPasswordModal;