import React, {useEffect, useState} from 'react';
import Hero from "./Hero";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

function Layout({children}) {
    const [showSidebar, setShowSidebar] = useState(false)

    const location = useLocation()

    const [title, setTitle] = useState("")
    const [meta, setMeta] = useState("")
    const [metaSmall, setMetaSmall] = useState(false)

    useEffect(() => {
        if (location.pathname === '/rec-leagues/') {
            setTitle("Rec Leagues")
            setMeta("Hometown Hoops Rec Basketball Leagues offer a unique opportunity for basketball\n" +
                "lovers of all skill levels to stay active, connect with others, and have fun on the court.\n" +
                "Our leagues provide a competitive yet welcoming environment that emphasizes\n" +
                "community building and sportsmanship. Join us for a season of fun and competition with\n" +
                "Hometown Hoops Rec Basketball Leagues!")
            setMetaSmall(true)
        } else if (location.pathname === '/tournaments/') {
            setTitle("Tournaments")
            setMeta("Find information about upcoming basketball tournaments for players of all skill levels.\n" +
                "Our tournaments offer a competitive and fun environment to improve skills and connect\n" +
                "with others in the community. We offer a variety of tournaments, including youth, adult,\n" +
                "cash prize, and 4-on-4 tournaments. Don't miss out on the chance to register and join\n" +
                "the action!")
            setMetaSmall(true)
        } else if (location.pathname === '/camps/') {
            setTitle("Travel Teams")
            setMeta("At Hometown Hoops, we're not just about basketball –" +
            " we're about building better players and better people both on" +
            " and off the court. Our travel teams are where competitive spirit " +
            "meets personal growth. Led by dedicated coaches and staff, we" +
            " focus on improving skills, fostering teamwork, and promoting " +
            "leadership qualities in our players. Whether you're just starting " +
            "out or looking for more exposure, our program is designed to help" +
            " you reach your full potential! ")
            setMetaSmall(true)
        } else {
            setTitle("Hometown Hoops")
            setMeta("We are a local organization bringing basketball to people of all ages and skill levels")
            setMetaSmall(false)
        }
    }, [location.pathname])

    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);

    return (
        <div className={'layout'}>
            <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} isAuthenticated={isAuthenticated}/>
            <Hero setShowSidebar={setShowSidebar} title={title} meta={meta} metaSmall={metaSmall} isAuthenticated={isAuthenticated}/>
            {children}
            <Footer/>
        </div>
    );
}

export default Layout;