import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import axios from "axios";
import Config from "../Config";
import Layout from "../Container/Layout/Layout";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";

function LeaguesSchedule() {
    const location = useLocation()

    const [league, setLeague] = useState(null)
    const [scheduleImg, setScheduleImg] = useState(null)

    const [teams, setTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([])

    useEffect(() => {
        const id = location.pathname.split('/')[2]

        const config = Config()
        axios.post(`${process.env.REACT_APP_API_URL}/api/get-league-schedule/`, {id: id}, config)
            .then(response => {
                setLeague(response.data.name)
                setScheduleImg(response.data.img)
                setTeams(response.data.teams)
                if (response.data.teams.length > 0) {
                    setSelectedTeam(response.data.teams[0]['id'])
                }
            })
            .catch(err => {
                console.log(err.message())
            })
    }, [])

    // useEffect(() => {
    //     const id = location.pathname.split('/')[2]
    //
    //     const config = Config()
    //     axios.post(`${process.env.REACT_APP_API_URL}/api/get-league-teams/`, {id: id}, config)
    //         .then(response => {
    //             setTeams(response.data)
    //             setSelectedTeam(1)
    //         })
    //         .catch(err => {
    //             console.log(err.message())
    //         })
    // }, [])

    useEffect(() => {
        if (selectedTeam !== null) {
            const config = Config()
            axios.post(`${process.env.REACT_APP_API_URL}/api/get-team-stats/`, {id: selectedTeam + 1}, config)
                .then(response => {
                    setSelectedTeamMembers(response.data)
                })
                .catch(err => {
                    console.log(err.message())
                })
        }
    }, [selectedTeam])

    const columns = [
        "Team", "Wins", "Losses"
    ]

    const [teamColumns, setTeamColumns] = useState([
        {name: "Player Stats", state: 0, key: "name", sort: false},
        {name: "Points Per Game", state: 0, key: "points", sort: true},
        {name: "Rebounds Per Game", state: 0, key: "rebounds", sort: true},
        {name: "Assists Per Game", state: 0, key: "assists", sort: true}
    ])

    const sort = (key, order) => {
        setTeamColumns(teamColumns.map((item) => {
            if (item.key === key) {
                item.state = !item.state
                return item
            } else
                return item
        }))

        if (!order) {
            setSelectedTeamMembers(
                [...selectedTeamMembers].sort((a, b) => {
                    if (a[key] < b[key]) {
                        return -1;
                    }
                    if (a[key] > b[key]) {
                        return 1;
                    }
                    return 0;
                })
            );
        } else {
            setSelectedTeamMembers(
                [...selectedTeamMembers].sort((a, b) => {
                    if (a[key] > b[key]) {
                        return -1;
                    }
                    if (a[key] < b[key]) {
                        return 1;
                    }
                    return 0;
                })
            );
        }
    };


    return (
        <Layout>
            <div className="container my-5">

                <p style={{fontSize: 32, fontWeight: 600}}>{league}</p>

                <div class=".text-center" >
                    {scheduleImg ?
                        <img className={'w-60 mx-auto d-block img-fluid'} src={'https://i.imgur.com/Feifuw2.png'}/>//src={'https://drive.google.com/uc?export=view&id=' + scheduleImg}/>
                        :
                        <p>No schedule available yet, keep an eye out for updates.</p>
                    }
                </div>

                <div className="row my-5">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <p style={{fontSize: 22, fontWeight: 600}}>League Standing</p>
                        <table className="table">
                            <thead>
                            <tr>
                                {columns.map(item => {
                                    return (
                                        <th className={'team-th'}>
                                            {item}
                                        </th>
                                    )
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {teams.map(item => {
                                return (
                                    <tr>
                                        <td className={'team-td team-name'} onClick={() => {
                                            setSelectedTeam(item.id)
                                        }}>
                                            {item.leagues_register.team_name}
                                        </td>
                                        <td className={'team-td'}>
                                            {item.wins}
                                        </td>
                                        <td className={'team-td'}>
                                            {item.losses}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <p style={{fontSize: 22, fontWeight: 600}}>Player Stats</p>
                        <table className="table">
                            <thead>
                            <tr>
                                {teamColumns.map(item => {
                                    return (
                                        <th style={{cursor: "pointer"}} className={'team-th'} onClick={() => {item.sort && sort(item.key, item.state)}}>
                                            {item.name}  {item.sort ? (item.state ? <BsArrowUp/> : <BsArrowDown/>) : ""}
                                        </th>
                                    )
                                })}
                            </tr>
                            </thead>

                            <tbody>
                            {selectedTeamMembers.map(item => {
                                return (
                                    <tr>
                                        <td className={'team-td'}>
                                            {item.name}
                                        </td>
                                        <td className={'team-td'}>
                                            {item.points}
                                        </td>
                                        <td className={'team-td'}>
                                            {item.rebounds}
                                        </td>
                                        <td className={'team-td'}>
                                            {item.assists}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </Layout>
    );
}

export default LeaguesSchedule;