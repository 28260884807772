import React, {useEffect, useRef, useState} from 'react';
import {AiFillCloseCircle, AiOutlineClose} from "react-icons/ai";
import axios from "axios";
import Config from "../../Config";
import {toast} from "react-hot-toast";

export function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

function RegisterModal({clickedTournament, get_tournaments, handleCloseRegModal, type}) {
    const formRef = useRef()
    const [modalStyle] = useState(getModalStyle);

    const [formSubmitted, setFormSubmitted] = useState(false)

    let guid = () => {
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    const [data, setData] = useState({
        id: clickedTournament.id,
        ...(type === 'camps' ? {
            parent_first_name: "",
            parent_last_name: "",
            children: [{id: guid(), name: "", age: ""}]
        } : {
            captain_first_name: "",
            captain_last_name: "",
            team_name: "",
        }),
        email: "",
        cell_number: "",
        type: type
    })

    const submit = () => {
        const config = Config()

        if (type === 'camps' && data.children.length <= 0) {
            toast.error("Please add at least one child")
        }

        if (!clickedTournament.edit) {
            axios.post(`${process.env.REACT_APP_API_URL}/api/create-register/`, data, config)
                .then(response => {
                    console.log(response.data)
                    setFormSubmitted(true)
                    get_tournaments()
                })
                .catch(err => console.log(err.message()))
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/api/edit-register/`, data, config)
                .then(response => {
                    console.log(response.data)
                    setFormSubmitted(true)
                    get_tournaments()
                })
                .catch(err => console.log(err.message()))
        }
    }

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const updateChild = (e, item) => {
        setData({
            ...data, children: data.children.map((child) => {
                if (child.id === item.id) {
                    child[e.target.name] = e.target.value
                }
                return child
            })
        })
    }

    useEffect(() => {
        if (clickedTournament.edit) {
            const config = Config()
            axios.post(`${process.env.REACT_APP_API_URL}/api/get-register/`, {id: clickedTournament.id, type: type}, config)
                .then(response => {
                    console.log(response.data)
                    setData({...response.data, type: type})
                })
                .catch(err => console.log(err.message()))
        }
    }, [clickedTournament])

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div style={modalStyle}
             className={'modal-body'}>
            {!formSubmitted ?
                <form ref={formRef}>
                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'modal-body-text'}>{type === 'camps' ? "Register your children here" : "Register your team here"}</p>
                        <AiOutlineClose onClick={handleCloseRegModal} className={'modal-body-close'}/>
                    </div>
                    {type !== 'camps' ?
                        <>
                            <p className={'modal-body-field-text'}>Captain First Name</p>
                            <input name={'captain_first_name'} required={true} value={data.captain_first_name}
                                   onChange={onChange} className={'modal-body-field form-control'} type={'text'}
                                   placeholder={'First Name'}/>

                            <p className={'modal-body-field-text'}>Captain Last Name</p>
                            <input name={'captain_last_name'} value={data.captain_last_name}
                                   onChange={onChange} required={true}
                                   className={'modal-body-field form-control'} type={'text'} placeholder={'Last Name'}/>

                            <p className={'modal-body-field-text'}>Team Name</p>
                            <input name={'team_name'} required={true} value={data.team_name}
                                   onChange={onChange} className={'modal-body-field form-control'} type={'text'}
                                   placeholder={'Team Name'}/>
                        </>
                        :
                        <>
                            <p className={'modal-body-field-text'}>Parent First Name</p>
                            <input name={'parent_first_name'} required={true} value={data.parent_first_name}
                                   onChange={onChange} className={'modal-body-field form-control'} type={'text'}
                                   placeholder={'First Name'}/>

                            <p className={'modal-body-field-text'}>Parent Last Name</p>
                            <input name={'parent_last_name'} value={data.parent_last_name}
                                   onChange={onChange} required={true}
                                   className={'modal-body-field form-control'} type={'text'} placeholder={'Last Name'}/>
                        </>
                    }
                    <p className={'modal-body-field-text'}>Email</p>
                    <input name={'email'} required={true} value={data.email}
                           onChange={onChange} className={'modal-body-field form-control'} type={'email'}
                           placeholder={'Email Name'}/>

                    <p className={'modal-body-field-text'}>Cell Phone Number</p>
                    <input name={'cell_number'} required={true} value={data.cell_number}
                           onChange={onChange} className={'modal-body-field form-control'} type={'number'}
                           placeholder={'Phone Number'}/>

                    {type === 'camps' && <>
                        <div className="row">
                            {data.children.map((item, index) => {
                                return (
                                    <>
                                        <div className="col-5">
                                            <p className={'modal-body-field-text'}>Child Name</p>
                                            <input
                                                onChange={(e) => updateChild(e, item)}
                                                name={'name'}
                                                value={item.name}
                                                required={true} className={'modal-body-field form-control'}/>
                                        </div>
                                        <div className="col-5">
                                            <p className={'modal-body-field-text'}>Child Age</p>
                                            <input
                                                onChange={(e) => updateChild(e, item)}
                                                name={'age'}
                                                value={item.age}
                                                type={'number'}
                                                required={true} className={'modal-body-field form-control'}/>
                                        </div>
                                        <div className="col-2" style={{display: 'flex'}}>
                                            <AiFillCloseCircle
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        children: data.children.filter(_item => _item.id !== item.id)
                                                    })
                                                }}
                                                style={{margin: "auto 0"}}/>
                                        </div>
                                    </>
                                )
                            })}
                        </div>

                        {data.children.length < 4 &&
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setData({
                                        ...data,
                                        children: [...data.children, {id: guid(), name: "", age: ""}]
                                    })
                                }}
                                className={'btn modal-body-btn'}>+ Add Child</button>
                        }
                    </>
                    }

                    <div style={{display: 'flex'}}>
                        <button onClick={(e) => {
                            e.preventDefault()
                            const form = formRef.current;
                            if (!form.checkValidity()) {
                                form.reportValidity()
                                return
                            } else {
                                submit()
                            }
                        }} className={'btn modal-body-btn'}>
                            {!clickedTournament.edit ? "Save" : "Edit"}
                        </button>
                    </div>
                </form>
                :
                <>
                    <p className={'modal-body-text'}>Thank you for your registration, we’ll get back in touch within 3-5 business
                        days</p>

                    <div style={{display: 'flex', marginTop: 10}}>
                        <button onClick={handleCloseRegModal} className={'btn modal-body-btn'}>
                            Close
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default RegisterModal;