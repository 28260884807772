import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SIGNUP_FAILED,
    LOAD_USER_SUCCESS,
    SIGNUP_SUCCESS
} from "../actions/types";

const initialState = {
    access_token: null,
    isAuthenticated: false,
    user: {
        subscription: null
    }
}

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('access_token', payload.access_token)
            return {
                ...state,
                access_token: payload.access_token,
                isAuthenticated: true,
                user: payload.user
            }
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                user: payload.user
            }
        case LOGOUT_SUCCESS:
            localStorage.removeItem('access_token')
            return {
                access_token: null,
                isAuthenticated: false,
                user: {
                    subscription: null
                }
            }
        default:
            return state
    }
}