import React, {useEffect, useState} from 'react';
import Layout from "../Container/Layout/Layout";
import {BsFillCalendarFill} from "react-icons/bs";
import {MdLocationOn} from "react-icons/md";
import tournaments from '../assets/images/tournaments.jpg';
import {Modal} from "@mui/material";
import RegisterModal from "../Container/Modal/RegisterModal";
import axios from "axios";
import Config from "../Config";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function Tournaments({}) {
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.actions.isAuthenticated);
    const [openRegModal, setOpenRegModal] = useState(false)
    const handleOpenRegModal = (tournament) => {
        setClickedTournament(tournament)
        setOpenRegModal(true)
    }
    const handleCloseRegModal = () => {
        setOpenRegModal(false)
    }

    const [tournaments, setTournaments] = useState([])
    const [clickedTournament, setClickedTournament] = useState(null)

    const get_tournaments = () => {
        const config = Config()
        axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments/`, config)
            .then(response => {
                console.log(response.data)
                setTournaments(response.data)
            })
            .catch(error => console.log(error.message()))
    }

    useEffect(() => {
        get_tournaments()
    }, [])

    return (
        <Layout>
            <div className="container">
                <div className="tournaments-container">
                    {tournaments.length === 0 &&
                        <p className="no-tournaments-title">There are no ongoing
                            tournaments at this time, keep an eye out for emails or updates here about upcoming
                            events</p>
                    }

                    {tournaments.map((tournament) => {
                        return (
                            <>
                                <p className="tournaments-title">{tournament.name}</p>

                                <div style={{display: 'flex', marginBottom: 10}}>
                                    <p className="tournaments-reg-title">Registration Open !!</p>

                                    <button onClick={() => {
                                        if (!isAuthenticated) {
                                            navigate('/login/')
                                        } else {
                                            handleOpenRegModal(tournament)
                                        }
                                    }}
                                            className={'btn tournaments-reg-btn'}>
                                        {(!tournament.edit || !isAuthenticated) ? "Register your team here" : "Edit Registration Information"}
                                    </button>
                                </div>


                                <div className={'tournaments-reg-meta-container'}>
                                    <BsFillCalendarFill className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{tournament.openDate} - {tournament.closeDate}</p>
                                </div>

                                <div className={'tournaments-reg-meta-container'}>
                                    <MdLocationOn className="tournaments-reg-meta-text"/>
                                    <p className="tournaments-reg-meta-text">{tournament.location}</p>
                                </div>

                                <div className="row my-3">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <p className={'tournaments-rules-title'}>Tournaments Rules</p>
                                        {/*<p className={'tournaments-rules-meta'}>&#x2022; Lorem Ipsum is simply dummy text of the printing </p>*/}

                                        {tournament['rules'].map((tournamentRule) => {
                                            return (
                                                <p className={'tournaments-rules-meta'}>
                                                    &#x2022; {tournamentRule.rule}
                                                </p>
                                            )
                                        })}

                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <img className="tournaments-container-img"
                                             src={'https://drive.google.com/uc?export=view&id=' + tournament.img}/>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            {isAuthenticated &&
                <Modal
                    open={openRegModal}
                    onClose={handleCloseRegModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <RegisterModal clickedTournament={clickedTournament}
                                   type={'tournaments'}
                                   get_tournaments={get_tournaments}
                                   handleCloseRegModal={handleCloseRegModal}/>
                </Modal>
            }
        </Layout>
    );
}

export default Tournaments;